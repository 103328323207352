import React, { useState, useEffect } from 'react';
import axios from 'axios';

export default function PermissionsList({ apiEndpoint, resourceKey, selectedOptions, onDataUpdate }) {
    const token = localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).token : null;
    const [loading, setLoading] = useState(true);
    const [permissions, setPermissions] = useState([]);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    };
    const getPermissions = async () => {
        try {
            const response = await axios.get(apiEndpoint, config);
            const data = response.data.data
            setPermissions(data);
        } catch (error) {
            console.error(`Error fetching permissions for ${resourceKey}:`, error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        getPermissions();
    }, []);

    const handleSelectionChange = (updatedSelections) => {
        // Notify the parent component about the new selections
        onDataUpdate(updatedSelections);
    };

    const handleCheckboxChange = (e, item) => {

    };

    return (
        <>
            {loading ? (
                <p>Loading {resourceKey} permissions...</p>
            ) : (
                permissions.length > 0 ? (
                    permissions.map((item, index) => (
                        <div className="form-check mt-3" key={`${resourceKey}-${index}`}>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={`${resourceKey}-check-${index}`}
                                onChange={(e) => handleCheckboxChange(e, item)}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`${resourceKey}-check-${index}`}
                            >
                                {item.name}
                            </label>
                        </div>
                    ))
                ) : (
                    <p>No permissions found for {resourceKey}.</p>
                )
            )}
        </>
    );
}
