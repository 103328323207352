import React, { useState, useEffect } from "react";
import DashboardNavigation from "./ViewProject/newStructure/DashboardNavigation";
import UserRoles from "./ViewProject/newStructure/Settings/UserRoles";
import {Col, Row} from "reactstrap";
const MainSettings = props => {
    const leftmenus = [
        {
            title:'User Roles',
            key:"1"
        }
    ]
    const [activeMenu, setActiveMenu]=useState("1")
    return (
        <div className="page-content">
            <DashboardNavigation projectTitle={"Settings"} />
            <div className={"container-fluid px-3"}>
                <Row>
                    <Col xl={2} md={3}>
                        <div className={"container-fluid border-left settings"}>
                            {
                                leftmenus.map((menu, index)=>(
                                    <div onClick={()=>setActiveMenu(menu.key)} className={activeMenu===menu.key ? "active-setting-menu" : "setting-menu"} key={index}>
                                        {menu.title}
                                    </div>
                                ))
                            }
                        </div>
                    </Col>
                    <Col xl={10} md={10}>
                        <UserRoles />
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default MainSettings;