import React, {useEffect, useState} from "react"
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import {useLocation, useParams} from "react-router-dom";
export default function DashboardNavigation(props){
    const[hashValue, sethashValue] = useState("1")
    const {id} = useParams()
    const {hash} = useLocation()
    useEffect(()=>{
        sethashValue(hash.replace('#', ''))
    }, [])
    return (
        <>
            <div className={"navigation-title mb-3 px-3"}>
                    <h4>{props.projectTitle}</h4>
            </div>
            <div className={"container-fluid py-2 mb-3 dashboard-navigation"}>
                <div className={"container-fluid px-3"}>
                    <Nav className={"d-flex justify-content-between"}>
                        <NavItem  className={classnames({
                            active: !hashValue || hashValue==="1",
                        })}>
                            <NavLink
                                style={{ cursor: "pointer" }}
                               href={`/dashboard/${id}#1`}
                            >
                                Overview
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "2",
                        })}>
                            <NavLink href={`/schedule/${id}#2`}
                                style={{ cursor: "pointer" }}


                            >
                                Scheduler
                            </NavLink>
                        </NavItem>
                        <NavItem  className={classnames({
                            active: hashValue === "3",
                        })}>
                            <NavLink  href={`/daily-logs/${id}#3`}
                                style={{ cursor: "pointer" }}

                            >
                                Daily Logs
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "4",
                        })}>
                            <NavLink  href={`/materials/${id}#4`}
                                style={{ cursor: "pointer" }}


                            >
                                Materials
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "5",
                        })}>
                            <NavLink  href={`/equipment/${id}#5`}
                                      style={{ cursor: "pointer" }}


                            >
                                Equipment
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "6",
                        })}>
                            <NavLink  href={`/labour/${id}#6`}
                                      style={{ cursor: "pointer" }}


                            >
                                Labour
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "9",
                        })}>
                            <NavLink  href={`/documents/${id}#9`}
                                      style={{ cursor: "pointer" }}


                            >
                               Documents
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "7",
                        })}>
                            <NavLink  href={`/collaborators/${id}#7`}
                                      style={{ cursor: "pointer" }}


                            >
                                Collaborators
                            </NavLink>
                        </NavItem>
                        <NavItem className={classnames({
                            active: hashValue === "8",
                        })}>
                            <NavLink  href={`/settings/${id}#8`}
                                      style={{ cursor: "pointer" }}


                            >
                                Settings
                            </NavLink>
                        </NavItem>
                    </Nav>
                </div>
            </div>

        </>
    )
}