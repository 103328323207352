import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row, Nav, NavItem, NavLink, TabContent, TabPane, Modal , Progress } from "reactstrap";

import classnames from "classnames";
import RadialChart from "./RadialChart";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import * as url from "../../helpers/jengasmart_api_urls";
import moment from 'moment';
import axios from 'axios';
import queryString from 'query-string';
import DailyLogs from './DailyLogs';
import Schedule from './Schedule';
import Materials from './Materials';
import Equipment from './Equipment';
import Labour from './Labour';
import Collaborators from './Collaborators';

import { useOpenWeather } from 'react-open-weather';
import {APIProvider, Map , Marker} from '@vis.gl/react-google-maps';
import Settings from './Settings';
import { useScheduleData } from '../../hooks/scheduleHook';


const ViewProject = (props) => {
    //meta title
    document.title = "Project | Jenga App";
    const parsed = queryString.parse(location.search);

    const MILESTONE_URL = process.env.REACT_APP_API_BASE + "milestones/project/" + props.match.params.id;
    

    let config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
      }
    };
    
    const [activeTab, setactiveTab] = useState("1");
    const GMAP_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    const { data, isLoading, errorMessage } = useOpenWeather({
      key: process.env.REACT_APP_WEATHER_API_KEY,
      lat: '-1.286389',
      lon: '36.817223',
      lang: 'en',
      unit: 'metric', // values are (metric, standard, imperial)
    }); 

    const [projectError, setProjectError] = useState('');
    const [project, setProject] = useState({});
    const [stats, setStats] = useState({});
    const [totalCosts, setTotalCosts] = useState({});
    const [totalMat, setTotalMat] = useState({});
    const [totalEqu, setTotalEqu] = useState({});
    const [totalLab, setTotalLab] = useState({});

    const [acceptModal, setAcceptModal] = useState(false);
    const [pageCollabItem, setPageCollabItem] = useState({});

    const [acceptError, setAcceptError] = useState('');

    const [graphLoading, setGraphLoading] = useState(true);
    const [isCardLoading, setCardLoading] = useState(false)

    const [projectOwner, setProjectOwner] = useState({});
    const [projectId, setProjectId] = useState(props.match.params.id);
    const  {projectScheduleData} = useScheduleData(projectId);
    const [scheduleStats, setScheduleStats] = useState({});
    const [progress, setProgress] =useState([])

    const [weatherDetails, setWeatherDetails] = useState({});


    useEffect(() => {
      setactiveTab(parsed.selectedtab);
      
     
      const notStarted = projectScheduleData.filter(item => item.status === "Not Started")
      const inProgress = projectScheduleData.filter(item => item.status === "In Progress")
      const completed = projectScheduleData.filter(item => item.status === "Completed")

      setScheduleStats({
        "notStarted" : notStarted.length,
        "inProgress" : inProgress.length,
        "completed": completed.length
      })
      
    }, []);

    useEffect(() => {
        
          
          var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + props.match.params.id;
      
          axios.get(api_url, config)
          .then(res => {
            setProject(res.data.data);
            if (res.data.data.latitude){
                var config1 = {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                    }
                };
                var weather_url = `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_APICOM_KEY}&q=${res.data.data.latitude},${res.data.data.longitude}`

                axios.get(weather_url, config1)
                    .then(res => {
                        setWeatherDetails(res.data);
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error)
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
            } else {
                var config = {
                    headers: {
                        'Content-Type': 'application/json',
                        // 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                    }
                };

                // var weather_url = 'https://api.openweathermap.org/data/2.5/weather?q=Nairobi,Kenya&APPID=d72ba1d925e56936cd076bd8a02bbb6a';

                var weather_url = `https://api.weatherapi.com/v1/current.json?key=${process.env.REACT_APP_WEATHER_APICOM_KEY}&q=-0.299194,36.061444`

                axios.get(weather_url, config)
                    .then(res => {
                        setWeatherDetails(res.data);
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log(error)
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            console.log('Error', error.message);
                        }
                    });
            }
            console.log("Latitude", res.data.data.latitude)
            var collabItem = res.data.data.projectCollaborators.find(item => {
              return item.memberEmail === JSON.parse(localStorage.getItem('authUser')).user.email;
            });

            setProjectOwner(collabItem)

            if(collabItem.accepted === false) {
              setPageCollabItem(collabItem);
              setAcceptModal(true);
            } 
            else {
              setAcceptModal(false)
            }
          })
          .catch(error => {
            if (error.response) {
              if(error.response.status === 401 || error.response.status === 403) {
                  props.history.push('/login');
              }
              else {
                setProjectError(error.response.data.error[0]);
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
          });
      }, []);

      useEffect(() => {
        
          var progress_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/tasks/' + props.match.params.id;
          var stats_url = process.env.REACT_APP_API_BASE + url.GET_STATS + '/project/' + props.match.params.id;
          
          setCardLoading(true)
          axios.get(progress_url, config).then((res)=>{
              setProgress(res.data)
              console.log(res.data, "Progress")
          }).catch((error)=>{

          })

          axios.get(stats_url, config)
          .then(res => {
            setStats(res.data.data);
            console.log(res.data.data)
            var totalCostObj = {
              title: "Total Expense",
              combinedTotal: 325,
              rank: "+$20.9k",
              isDoller: true,
              statusColor: "success",
            };
            var totalMatObj = {
              title: "Materials",
              combinedTotal: 20,
              rank: "+2 Projects",
              isDoller: false,
              statusColor: "danger",
            };
            var totalEquObj = {
              title: "Equipment",
              combinedTotal: 12,
              rank: "+$2.8k",
              isDoller: false,
              statusColor: "success",
            };
            var totalLabObj = {
              title: "Labour Role",
              combinedTotal: 15,
              rank: "+$2.75%",
              isDoller: false,
              statusColor: "success",
            };

            totalCostObj['combinedTotal'] = res.data.data.totalExpenditure;
            totalMatObj['combinedTotal'] = res.data.data.totalMaterials;
            totalEquObj['combinedTotal'] = res.data.data.totalEquipment;
            totalLabObj['combinedTotal'] = res.data.data.totalLabour;

            setTotalCosts(totalCostObj);
            setTotalMat(totalMatObj);
            setTotalEqu(totalEquObj);
            setTotalLab(totalLabObj);
            setGraphLoading(false);
          })
          .catch(error => {
            if (error.response) {
              if(error.response.status === 401 || error.response.status === 403) {
                  props.history.push('/login');
              }
              else {
                setProjectError(error.response.data.error[0]);
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log('Error', error.message);
            }
          })
          .finally(() => setCardLoading(false));
      }, []);

      const toggle = (tab) => {
        if (activeTab !== tab) {
          setactiveTab(tab);
          props.history.push(`/projects/${props.match.params.id}?selectedtab=${tab}`);
        }
      };

      const options = {
        chart: {
            height: 50,
            type: "line",
            toolbar: { show: false },
        },
        colors: ["#5156be"],
        stroke: {
            curve: 'smooth',
            width: 2,
        },
        xaxis: {
            labels: {
                show: false
            },
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false
            }
        },
        yaxis: {
            labels: {
                show: false
            }
        },
        tooltip: {
            fixed: {
                enabled: false
            },
            x: {
                show: false
            },
            y: {
                title: {
                    formatter: function (seriesName) {
                        return ''
                    }
                }
            },
            marker: {
                show: false
            }
        }
    };

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };





  //   async function fetchProjectMilestones(){
  //     const {data} = await axios.get(MILESTONE_URL,config)
 
  //     return data;
  //  }

  //  const { data: milestones = [] } = useQuery({
  //   queryKey: ['milestones'],
  //   queryFn: fetchProjectMilestones,
  //   initialData: []
  // });

    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumbs */}
            <i
              className="fas fa-arrow-circle-left"
              style={{
                fontSize: 20,
                color: "blue",
                cursor: "pointer",
                marginBottom: 10,
              }}
              onClick={() => props.history.push("/projects")}
            ></i>{" "}
            <Breadcrumbs title="Project" breadcrumbItem={project.projectName} />
            <Row>
              <Col sm={12}>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {
                        toggle("1");
                      }}
                    >
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "2",
                      })}
                      onClick={() => {
                        toggle("2");
                        window.location.reload();
                      }}
                    >
                      Schedule
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "3",
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Daily Logs
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "4",
                      })}
                      onClick={() => {
                        toggle("4");
                      }}
                    >
                      Tasks
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "5",
                      })}
                      onClick={() => {
                        toggle("5");
                      }}
                    >
                      Materials
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "6",
                      })}
                      onClick={() => {
                        toggle("6");
                      }}
                    >
                      Equipment
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "7",
                      })}
                      onClick={() => {
                        toggle("7");
                      }}
                    >
                      Labour
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "8",
                      })}
                      onClick={() => {
                        toggle("8");
                      }}
                    >
                      Collaborators
                    </NavLink>
                  </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                                active: activeTab === "9",
                            })}
                            onClick={() => {
                                toggle("9");
                            }}
                        >
                            Settings1
                        </NavLink>
                    </NavItem>
                </Nav>

                <TabContent activeTab={activeTab} className="p-3 text-muted">
                  <TabPane tabId="1">
                    {/* New view */}

                    <div className="d-flex flex-row flex-wrap flex-xl-nowrap  gap-3 w-100 ">
                      <div className="d-flex flex-column col-12 col-lg-8">
                        <div className="d-flex flex-row flex-md-wrap w-100 gap-2 mb-3">
                          <Col className="p-3 rounded-3 border flex-grow-1">
                            <h6 className="fw-semibold text-black">
                              Start Date
                            </h6>
                            <p className="fw-semibold primary-text-color fs-5 mb-0">
                              {moment(project.startDate).format(
                                "MMMM Do, YYYY"
                              )}
                            </p>
                          </Col>
                          <Col className="p-3 rounded-3 border flex-grow-1">
                            <h6 className="fw-semibold text-black">Due Date</h6>
                            <p className="fw-semibold primary-text-color fs-5 mb-0">
                              {moment(project.endDate).format("MMMM Do, YYYY")}
                            </p>
                          </Col>
                          <Col className="p-3 rounded-3 border flex-grow-1">
                            <h6 className="fw-semibold text-black">Timeline</h6>
                            {/* <p style={{ color: ((moment(new Date()) < moment(project.endDate)) ? 'green' : 'red') }}>{moment().to(moment(project.endDate))}</p> */}
                            <p
                              className="fw-semibold primary-text-color fs-5 mb-0"
                              style={{
                                color:
                                  moment(new Date()) < moment(project.endDate)
                                    ? ""
                                    : "red",
                              }}
                            >
                              {Math.abs(
                                moment(project.endDate).diff(
                                  moment(new Date()),
                                  "days"
                                )
                              ) +
                                " " +
                                (moment(new Date()) < moment(project.endDate)
                                  ? "days left"
                                  : "days overdue")}
                            </p>
                          </Col>
                        </div>
                        <div className="d-flex flex-row w-100 gap-2 mb-3">
                          <div className="flex-grow-1">
                            <Card className="p-3 rounded-3 border black-50-bg w-100 h-100  mb-0">
                              <CardBody className="p-1">
                                <div className="d-flex flex-row justify-content-between mb-4">
                                  <h6 className="primary-text-color fw-bold">
                                    {" "}
                                    Project Details{" "}
                                  </h6>
                                  {/* <Link
                                    to="#"
                                    className="d-flex flex-row primary-text-color fw-semibold align-items-baseline"
                                  >
                                    <span> View More </span>
                                    <i
                                      className="fas fa-chevron-right primary-text-color"
                                      style={{
                                        fontSize: "13px",
                                        color: "",
                                        marginLeft: "5px",
                                      }}
                                    ></i>
                                  </Link> */}
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                    <div className="w-50">
                                      <h6 className="fw-bold">
                                        Project creator{" "}
                                      </h6>
                                    </div>
                                    <div className="w-50">
                                      <h6 className="fw-semibold">
                                        {projectOwner?.memberEmail}
                                      </h6>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                    <div className="w-50">
                                      <h6 className="fw-bold">Collaborators</h6>
                                    </div>
                                    <div className="w-50">
                                      <h6 className="fw-semibold">{project.projectCollaborators?.length}</h6>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                    <div className="w-50">
                                      <h6 className="fw-bold">Client</h6>
                                    </div>
                                    <div className="w-50">
                                      <h6 className="fw-semibold">{project.client}</h6>
                                    </div>
                                  </div>
                                  <div className="d-flex flex-row justify-between mb-3 border-bottom">
                                    <div className="w-50">
                                      <h6 className="fw-bold">Type</h6>
                                    </div>
                                    <div className="w-50">
                                      <h6 className="fw-semibold">{project.projectType}</h6>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                          <div className="flex-grow-1">
                            <Card className="p-3 rounded-3 border black-50-bg w-100  mb-0">
                              <CardBody className="p-1">
                                <div className="d-flex flex-row justify-content-between mb-2">
                                  <h6 className="primary-text-color fw-bold">
                                    Cost Tracking
                                  </h6>
                                  {/* <Link
                                    to="#"
                                    className="d-flex flex-row primary-text-color fw-semibold align-items-baseline"
                                  >
                                    <span> View More </span>
                                    <i
                                      className="fas fa-chevron-right primary-text-color"
                                      style={{
                                        fontSize: "13px",
                                        color: "",
                                        marginLeft: "5px",
                                      }}
                                    ></i>
                                  </Link> */}
                                </div>
                                <div className="d-flex flex-column">
                                  <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                                    <div className="w-50">
                                      {graphLoading ? (
                                        <div className="text-center mt-5 mb-5">
                                          <p className="placeholder-glow">
                                            <span
                                              className="placeholder col-4"
                                              style={{
                                                width: 150,
                                                height: 150,
                                                borderRadius: "50%",
                                              }}
                                            ></span>
                                          </p>
                                        </div>
                                      ) : (
                                        <RadialChart
                                          currency={project.currency}
                                          expenditure={stats.totalExpenditure}
                                          expectedBudget={project.budget}
                                        />
                                      )}
                                    </div>
                                    <div className="d-flex w-50 flex-column">
                                      <div className="mb-2 border-bottom">
                                        <h6 className="primary-text-color fw-bold mb-1 fs-5">
                                          Expenditure
                                        </h6>
                                        <p className="fw-semibold">
                                          {project.currency} {stats.totalExpenditure ? stats.totalExpenditure.toLocaleString(undefined , {minimumFractionDigits: 2}) : 0}
                                        </p>
                                      </div>
                                      <div className="mb-2 border-bottom">
                                        <h6 className="primary-text-color fw-bold mb-1 fs-5">
                                          Total Budget
                                        </h6>
                                        <p className="fw-semibold">
                                          {project.currency} {project.budget ? project.budget.toLocaleString(undefined , {minimumFractionDigits: 2}) : 0}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </div>
                        <div className="d-flex flex-row w-100">
                          <Card className="p-3 rounded-3 border black-50-bg w-100">
                            <CardBody>
                              <div className="d-flex flex-row justify-content-between mb-4">
                                <h6 className="primary-text-color fw-bold">
                                  {" "}
                                  Project Progress{" "}
                                </h6>
                                <a
                                  onClick={() => toggle("2")}
                                  // to={`/projects/${props.match.params.id}?selectedtab=2`}
                                  className="d-flex flex-row primary-text-color fw-semibold align-items-baseline"
                                >
                                  <span> Open Scheduler </span>
                                  <i
                                    className="fas fa-chevron-right primary-text-color"
                                    style={{
                                      fontSize: "13px",
                                      color: "",
                                      marginLeft: "5px",
                                    }}
                                  ></i>
                                </a>
                              </div>
                              <div className="d-flex flex-row justify-content-between mb-3">
                                <div>
                                  <h6 className="fw-medium text-black-50">
                                     {/* Check of the percentageCompletedTask is null*/}
                                      {
                                          stats.percentageCompletedTask ? <>
                                              { Math.round(stats.percentageCompletedTask)}% Completed
                                          </> : <>
                                          0% Completed
                                          </>
                                      }
                                  </h6>
                                  <h6 className="fw-semibold fs-6">
                                    {Math.abs(
                                      moment(project.endDate).diff(
                                        moment(new Date()),
                                        "days"
                                      )
                                    )}{" "}
                                    Days Remaining for {project.projectName}
                                  </h6>
                                </div>
                                <div>
                                  <h6 className="fw-medium text-black-50">
                                    {" "}
                                    Target Completion Date{" "}
                                  </h6>
                                  <h6 className="fw-semibold fs-6">
                                    {" "}
                                    {moment(project.endDate).format(
                                      "MMMM Do, YYYY"
                                    )}{" "}
                                  </h6>
                                </div>
                              </div>
                              <div className="d-flex flex-row mb-3">
                                <div className="w-100">
                                  <Progress
                                    value={stats.percentageCompletedTask}
                                    className="progress-lg"
                                    style={{
                                      height: "22px",
                                      backgroundColor: "#0000001F",
                                      borderRadius: "24px",
                                    }}
                                  ></Progress>
                                </div>
                              </div>
                              <div className="d-flex flex-row gap-3">
                                <div className="d-flex border-end task-border-color align-items-center">
                                  <h6 className="fw-bold mb-0">
                                    {" "}
                                    Total Tasks{" "}
                                  </h6>
                                  <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                      {progress.totalTasks}
                                    </h6>
                                  </div>
                                </div>
                                <div className="d-flex border-end task-border-color align-items-center">
                                  <h6 className="fw-bold mb-0"> Completed </h6>
                                  <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                        {progress.totalCompletedTasks}
                                    </h6>
                                  </div>
                                </div>
                                {/*<div className="d-flex border-end task-border-color align-items-center">
                                  <h6 className="fw-bold mb-0"> In Progress </h6>
                                  <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                     {scheduleStats.inProgress}
                                    </h6>
                                  </div>
                                </div>*/}
                                <div className="d-flex  task-border-color align-items-center">
                                  <h6 className="fw-bold mb-0"> Not Started </h6>
                                  <div className="task-badge mx-2">
                                    <h6 className="fw-bold primary-text-color mb-0">
                                      {progress.totalNotStartedTasks}
                                    </h6>
                                  </div>
                                </div>
                                
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                      {/* second column */}
                      <div className="d-flex flex-column flex-grow-1">
                        {/* <div className="card p-3 rounded-3 border black-50-bg w-100"></div> */}
                        {/* weather */}
                        <Card className="p-3 rounded-3 border black-50-bg w-100 mb-3" style={{height: "50%"}}>
                          {/* <CardBody className="p-2"> */}
                            <h6 className="primary-text-color fw-bold">
                              {weatherDetails?.location?.name}
                            </h6>
                            <p className="fw-semibold">{moment(weatherDetails?.location?.localtime).format(
                                "MMM Do, YYYY, h:mm"
                              )}</p>

                            <div className="mb-2 text-center">
                              <img src={weatherDetails?.current?.condition?.icon} alt="" />
                            </div>

                            <div className="d-flex flex-column">
                              <div className="d-flex flex-row justify-content-center align-items-center gap-3">
                                <div className="w-20">
                                  <div className="mb-2">
                                    <h1 className="primary-text-color fw-regular mb-1">
                                      {weatherDetails?.current?.temp_c}&deg;C
                                    </h1>
                                  </div>
                                </div>
                                <div className="d-flex w-80 flex-column">
                                  <div className="mb-2">
                                    <p className="primary-text-color fw-regular mb-1">
                                      {weatherDetails?.current?.condition?.text}
                                    </p>
                                    <p className="primary-text-color fw-regular mb-1">
                                      Humidity: <b>{weatherDetails?.current?.humidity}</b>
                                    </p>
                                    <p className="primary-text-color fw-regular">
                                      Wind: <b>{weatherDetails?.current?.wind_kph}</b>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="mb-2">
                                <p className="primary-text-color fw-regular">
                                  Powered by <a href="https://www.weatherapi.com/" title="Weather API">WeatherAPI.com</a>
                                </p>
                              </div>
                            </div>
                            {/* <ReactWeather
                              isLoading={isLoading}
                              errorMessage={errorMessage}
                              data={data}
                              lang="en"
                              locationLabel="Nairobi"
                              unitsLabels={{ temperature: 'C', windSpeed: 'Km/h' }}
                              showForecast
                            /> */}
                          
                          {/* </CardBody> */}
                        </Card>
                        {/* MAP */}
                        <Card className="rounded-3 border black-50-bg w-100 mb-3" style={{height: "50%"}}>
                          {/* <img
                            className="img-fluid"
                            src={gmap}
                            alt=""
                            
                            style={{ width: "100%", objectFit: "cover" }}
                          /> */}
                            {
                                project.latitude ? <>
                                    <APIProvider apiKey={GMAP_KEY}>
                                        <Map
                                            zoom={15}
                                            center={{ lat: project.latitude, lng: project.longitude }}
                                            gestureHandling={"greedy"}
                                            disableDefaultUI={true}
                                        >
                                            <Marker position={{ lat: project.latitude, lng: project.longitude }} />
                                        </Map>
                                    </APIProvider>
                                </> : <>
                                    <APIProvider apiKey={GMAP_KEY}>
                                        <Map
                                            zoom={15}
                                            center={{ lat: -0.299194, lng: 36.061444 }}
                                            gestureHandling={"greedy"}
                                            disableDefaultUI={true}
                                        >
                                            <Marker position={{ lat: -0.299194, lng: 36.061444 }} />
                                        </Map>
                                    </APIProvider>
                                </>
                            }
                        </Card>
                        {/* <Card className="rounded-3 border black-50-bg w-100 mb-3">
                          <CardBody>
                            <div className="d-flex flex-column">
                              <h6 className="primary-text-color fs-5 fw-bold mb-3">
                                Recent Activity
                              </h6>

                              <div className="d-flex flex-row border-bottom justify-content-between py-2">
                                <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                                  <div className="p-avatar d-flex justify-content-center align-items-center mr-3">
                                    <h6 className="text-white fw-bold mb-0">
                                      {" "}
                                      MM{" "}
                                    </h6>
                                  </div>
                                  <h6 className="fs-bold mb-0">
                                    {" "}
                                    Photo Added{" "}
                                  </h6>
                                </div>
                                <div className="align-self-center">
                                  {" "}
                                  <h6 className="fs-bold  mb-0">
                                    {" "}
                                    13 hours ago{" "}
                                  </h6>{" "}
                                </div>
                                <div className="align-self-center">
                                  <Link
                                    to="#"
                                    className="d-flex flex-row primary-text-color fw-semibold align-items-center"
                                  >
                                    <span> View </span>
                                    <i
                                      className="fas fa-chevron-right primary-text-color align-self-center"
                                      style={{
                                        fontSize: "13px",
                                        color: "",
                                        marginLeft: "5px",
                                      }}
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                              <div className="d-flex flex-row border-bottom justify-content-between py-2">
                                <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                                  <div className="p-avatar d-flex justify-content-center align-items-center mr-3">
                                    <h6 className="text-white fw-bold mb-0">
                                      {" "}
                                      JK{" "}
                                    </h6>
                                  </div>
                                  <h6 className="fs-bold mb-0">
                                    {" "}
                                    Invoice Created{" "}
                                  </h6>
                                </div>
                                <div className="align-self-center">
                                  {" "}
                                  <h6 className="fs-bold  mb-0">
                                    {" "}
                                    13 hours ago{" "}
                                  </h6>{" "}
                                </div>
                                <div className="align-self-center">
                                  <Link
                                    to="#"
                                    className="d-flex flex-row primary-text-color fw-semibold align-items-center"
                                  >
                                    <span> View </span>
                                    <i
                                      className="fas fa-chevron-right primary-text-color align-self-center"
                                      style={{
                                        fontSize: "13px",
                                        color: "",
                                        marginLeft: "5px",
                                      }}
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                              <div className="d-flex flex-row border-bottom justify-content-between py-2">
                                <div className="d-flex flex-row justify-content-center align-items-center gap-2">
                                  <div className="p-avatar  d-flex justify-content-center align-items-center mr-3">
                                    <h6 className="text-white fw-bold mb-0">
                                      {" "}
                                      JK{" "}
                                    </h6>
                                  </div>
                                  <h6 className="fs-bold mb-0"> Task Added </h6>
                                </div>
                                <div className="align-self-center">
                                  {" "}
                                  <h6 className="fs-bold  mb-0">
                                    {" "}
                                    13 hours ago{" "}
                                  </h6>{" "}
                                </div>
                                <div className="align-self-center">
                                  <Link
                                    to="#"
                                    className="d-flex flex-row primary-text-color fw-semibold align-items-center"
                                  >
                                    <span> View </span>
                                    <i
                                      className="fas fa-chevron-right primary-text-color align-self-center"
                                      style={{
                                        fontSize: "13px",
                                        color: "",
                                        marginLeft: "5px",
                                      }}
                                    ></i>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card> */}
                      </div>
                    </div>

                  </TabPane>
                  <TabPane tabId="2">
                    <Schedule
                      projectId={props.match.params.id}
                      projectName={project.projectName}
                      history={props.history}
                      // task={milestones}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <DailyLogs
                      projectId={props.match.params.id}
                      projectName={project.projectName}
                      history={props.history}
                    />
                  </TabPane>
                  {/* <TabPane tabId="4">
                    <Tasks
                      projectId={props.match.params.id}
                      projectName={project.projectName}
                      history={props.history}
                    />
                  </TabPane> */}
                  <TabPane tabId="5">
                    <Materials
                      projectId={props.match.params.id}
                      currency={project.currency}
                      history={props.history}
                    />
                  </TabPane>
                  <TabPane tabId="6">
                    <Equipment
                      projectId={props.match.params.id}
                      currency={project.currency}
                      history={props.history}
                    />
                  </TabPane>
                  <TabPane tabId="7">
                    <Labour
                      projectId={props.match.params.id}
                      currency={project.currency}
                      history={props.history}
                    />
                  </TabPane>
                  <TabPane tabId="8">
                    <Collaborators
                      projectId={props.match.params.id}
                      history={props.history}
                    />
                  </TabPane>
                  <TabPane tabId="9">
                    <Settings
                      projectId={props.match.params.id}
                      history={props.history}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>

          <Modal
            isOpen={acceptModal}
            /* toggle={() => {
                    tog_acceptModal();
                  }} */
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Accept Invite
              </h5>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <p>
                  Please click to accept the invite before you can interact with
                  this project.
                </p>
                <p>
                  Alternatively you can decline to be a part of this project.
                </p>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => {
                  declineProject();
                }}
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Decline
              </button>
              <button
                className="btn btn-primary"
                onClick={() => acceptProject()}
              >
                Accept
              </button>
            </div>
          </Modal>
        </div>
      </React.Fragment>
    );
}

export default ViewProject;