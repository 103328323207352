import React, { useState, useEffect, useRef } from "react"
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Form,
    Label,
    Input,
    Row,
    Table,
    Modal,
    Progress
} from "reactstrap";

import * as url from "../../../helpers/jengasmart_api_urls";
import {Link, useParams} from "react-router-dom";
import DashboardNavigation from "./DashboardNavigation";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";

const DocumentsFolder = (props) => {
    const {id, folder} = useParams()
    const [project, setProject] = useState({});
    const[folderPath,setFolderPath]=useState("")
    const [progress, setProgress] = useState(0);
    const[loading, setLoading]=useState(false)
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const fileInputRef = useRef(null);
    const folderInputRef = useRef(null);
    const[uploadingStatus, setUploadingStatus]=useState(false)
    const[files, setFiles]=useState([])
    const handleFileChange = (event) => {
        const files = event.target.files;
        uploadFiles(files, `${project.projectName.split(" ").join("")}`)
    };
    const uploadFiles = async (files, folderPath) =>{
        const api_url = process.env.REACT_APP_API_BASE + `files/upload`;
        const filesList = Array.from(files);
        const formData = new FormData();
        formData.append('folderPath', folderPath+'/'+folder);
        formData.append('createdBy', JSON.parse(localStorage.getItem('authUser')).user.id);
        filesList.forEach((file) => {
            formData.append('files', file);
        });
        try {
            setUploadingStatus(true)
            const uploadResponse = await axios.post(api_url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
                },
                onUploadProgress:(progressEvent)=>{
                    const progress  = (progressEvent.loaded / progressEvent.total) * 100
                    setProgress(progress);
                }
            });
            if (uploadResponse.status===201){
                toast.success("Files uploaded successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                getProjectDetails()
                setUploadingStatus(false)
                setProgress(0)
            } else {
                setUploadingStatus(false)
                setProgress(0)
            }

        } catch (error) {
            console.error(`Error uploading files:`, error);
            setUploadingStatus(false)
            setProgress(0)
        }
    }
    const handleFolderUpload = async (event) => {
        const api_url = process.env.REACT_APP_API_BASE + `folders/${project.projectName.split(" ").join("")}`;
        const files = event.target.files;
        if (files.length > 0) {
            const folderpath= files[0].webkitRelativePath.split('/')[0];
            setFolderPath(`${project.projectName.split(" ").join("")}`+'/'+folderpath)
            try {
                const payload = {
                    folderPath
                }
                const response = await axios.post(api_url, payload, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
                    },
                });
                if (response.status===201){
                    getProjectDetails()
                    uploadFiles(files, folderPath)
                }
                console.log('Folder uploaded successfully:', response);
            } catch (error) {
                console.error('Error uploading folder:', error);
            }
        }


    };
    const getProjectDetails = async() =>{
        setLoading(true)
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + id;
        const projectinfo =  await axios.get(api_url, config);
        if (projectinfo.status===200){
            setProject(projectinfo.data.data);
            getFiles(projectinfo.data.data)
            setLoading(false)
        }
        setLoading(false)
    }
    const getFiles = async (project)=>{

        const api_url = process.env.REACT_APP_API_BASE + `folders/${project.projectName.split(" ").join("")}/folderContent`;
        const payload = {
            folderPath:`${project.projectName.split(" ").join("")}/${folder}`,
        }
        const response = await axios.post(api_url,payload, config)
        console.log(response)
        if (response.status===200){
            setFiles(response.data.data.files)
        }
    }
    const downloadFiles = async (url) =>{
        const api_url = process.env.REACT_APP_API_BASE + `files/download`
        const payload = {
            filePath:url
        }
        const response = await axios.post(api_url, payload, config)
        if (response.status===200){
            window.open(response.data.data.downloadUrl, '_blank')
        }

    }
    useEffect(() => {
        getProjectDetails()

    }, []);

    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className="page-content">
                <DashboardNavigation projectTitle={"Document Files"} />
                <div className={"container-fluid px-3"}>
                    <Row>
                        <Col sm="12">
                            <Row>
                                <Col xl={6} md={6} xs={12}>
                                    <h4 className="card-title"><Link to={`/documents/${id}#9`} style={{color:'#194973'}}>Documents</Link> {'>'} {folder}</h4>
                                    <p className="card-title-desc mb-3">
                                        Explore files in this folder.
                                    </p>
                                </Col>
                                <Col xl={6} md={6} xs={12}>
                                    <div className={"container-fluid d-flex justify-content-end"}>

                                        <input
                                            type="file"
                                            webkitdirectory="true"
                                            style={{ display: 'none' }}
                                            ref={folderInputRef}
                                            onChange={handleFolderUpload}
                                        />

                                        <button
                                            className="btn btn-primary me-3"
                                            style={{ background: '#194973' }}
                                            onClick={() => fileInputRef.current.click()}
                                        >
                                            <img src="/File - Check.svg" alt="Upload File" /> Upload file
                                        </button>
                                        <input
                                            type="file"
                                            style={{ display: 'none' }}
                                            ref={fileInputRef}
                                            onChange={handleFileChange}
                                            multiple={true}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <Card>
                                {
                                    uploadingStatus && (
                                        <>
                                            Uploading
                                            <Progress
                                                value={progress}
                                                color="primary"
                                                className=""
                                            >
                                                <span>{progress}%</span>
                                            </Progress>
                                        </>
                                    )
                                }

                                <CardBody className={"p-0"}>
                                    <div className="table-responsive">
                                        {
                                            loading ? <>
                                            <p>Loading...</p>
                                            </> : <>
                                                <Table className="table mb-0">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>File size</th>
                                                        <th>Format</th>
                                                        <th>Last update</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        loading ? <>
                                                            <tr>
                                                                <td colSpan={4}>Loading...</td>
                                                            </tr>

                                                        </> : <>
                                                            {
                                                                files.length > 0 ? <>
                                                                    {
                                                                        files.map((file)=>(
                                                                            <>
                                                                                <tr onClick={()=>downloadFiles(file.public_id)}>
                                                                                    <td> <img src="/File.png" alt="File" /> {file.context.custom.originalFilename}</td>
                                                                                    <td>{file.bytes} bytes</td>
                                                                                    <td>{file.format}</td>
                                                                                    <td>{new Date(file.created_at).toDateString()}</td>
                                                                                </tr>
                                                                            </>
                                                                        ))
                                                                    }
                                                                </> : <>
                                                                    <td colSpan={4}>
                                                                        <p>There are no files in this folder</p>
                                                                    </td>
                                                                </>
                                                            }
                                                        </>
                                                    }
                                                    </tbody>
                                                </Table>
                                            </>
                                        }

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    );
}

export default DocumentsFolder
