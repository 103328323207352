import React, { useState, useEffect, useRef } from "react"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import {
    Alert,
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Form,
    Label,
    Input,
    Row,
    Table,
    Modal,
    Progress
} from "reactstrap";

import * as url from "../../../helpers/jengasmart_api_urls";
import {useParams} from "react-router-dom";
import DashboardNavigation from "./DashboardNavigation";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";

const Documents = (props) => {
    const {id} = useParams()
    const [project, setProject] = useState({});
    const[folderPath,setFolderPath]=useState("")
    const[folders, setFolders]=useState([])
    const[loading, setLoading]=useState(false)
    const [progress, setProgress] = useState(0);
    const [searchQuery, setSearchQuery] = useState("");
    const[files, setFiles]=useState([])
    const [filteredFolders, setFilteredFolders] = useState([]);
    const [filteredFiles, setFilteredFiles] = useState([]);
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const fileInputRef = useRef(null);
    const folderInputRef = useRef(null);
    const[uploadingStatus, setUploadingStatus]=useState(false)
    const [showCreateFolder,setShowCreateFolder]=useState(false)
    const handleCreateFolder = async ()=>{
        try {
            const payload = {
                folderPath:project.projectName.split(" ").join("") +'/'+folderPath,
                createdBy:JSON.parse(localStorage.getItem('authUser')).user.id
            };
            const api_url_folders = process.env.REACT_APP_API_BASE + `folders/${project.projectName.split(" ").join("")}`;
            const response = await axios.post(api_url_folders, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
                },
            });

            if (response.status === 201) {
                setShowCreateFolder(false)
                setFolderPath('')
                toast.success("Folder created successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                getProjectDetails();
            }
        } catch (error) {

        }
    }
    const handleFileChange = (event) => {
        const files = event.target.files;
        uploadFiles(files, `${project.projectName.split(" ").join("")}`)
    };
    const handleSearchChange = (event) => {
        const query = event.target.value
        setSearchQuery(query)
        const lowerCaseQuery =query.toLowerCase();
        const filteredFolders = folders.filter((folder) =>
            folder.name.toLowerCase().includes(lowerCaseQuery)
        );
        const filteredFiles = files.filter((file) =>
            file.context.custom.originalFilename.toLowerCase().includes(lowerCaseQuery)
        );
        setFilteredFolders(filteredFolders)
        setFilteredFiles(filteredFiles)

    };
    const uploadFiles = async (files, folderPath) =>{
        const api_url = process.env.REACT_APP_API_BASE + `files/upload`;
        const filesList = Array.from(files);
        const formData = new FormData();
        formData.append('folderPath', folderPath);
        formData.append('createdBy', JSON.parse(localStorage.getItem('authUser')).user.id);
        filesList.forEach((file) => {
            formData.append('files', file);
        });
        try {
            setUploadingStatus(true)
            const uploadResponse = await axios.post(api_url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
                },
                onUploadProgress:(progressEvent)=>{
                    const progress  = (progressEvent.loaded / progressEvent.total) * 100
                    setProgress(progress);
                }
            });
            if (uploadResponse.status===201){
                toast.success("Files uploaded successfully",{
                    position: toast.POSITION.TOP_RIGHT,
                    toastId: "logId"
                })
                getProjectDetails()
                setUploadingStatus(false)
                setProgress(0)
            } else {
                setUploadingStatus(false)
                setProgress(0)
            }

        } catch (error) {
            console.error(`Error uploading files:`, error);
            setUploadingStatus(false)
            setProgress(0)
        }
    }
    const handleFolderUpload = async (event) => {

        const files = event.target.files;
        const folderpath = files[0].webkitRelativePath.split('/')[0];
        const fullFolderPath = `${project.projectName.split(" ").join("")}/${folderpath}`;
        try {
            const payload = {
                folderPath: fullFolderPath,
                createdBy:JSON.parse(localStorage.getItem('authUser')).user.id
            };
            const api_url_folders = process.env.REACT_APP_API_BASE + `folders/${project.projectName.split(" ").join("")}`;
            const response = await axios.post(api_url_folders, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token,
                },
            });

            if (response.status === 201) {
                getProjectDetails();
                uploadFiles(files, fullFolderPath);
            }

            console.log('Folder uploaded successfully:', response);
        } catch (error) {
            console.error('Error uploading folder:', error);
        }
    };
    const getProjectDetails = async() =>{
        var api_url = process.env.REACT_APP_API_BASE + url.POST_PROJECT_DETAILS + '/' + id;
        const projectinfo =  await axios.get(api_url, config);
        if (projectinfo.status===200){
            setProject(projectinfo.data.data);
            getFolders(projectinfo.data.data)
            getFiles(projectinfo.data.data)
        }
    }
    const getFolders = async (project)=>{
        setLoading(true)
        const api_url = process.env.REACT_APP_API_BASE + `folders/${project.projectName.split(" ").join("")}`;
        const response = await axios.get(api_url, config)
        if (response.status===200){
            setFolders(response.data.data.folders)
            setLoading(false)
        } else {
            setLoading(false)
        }

    }
    const getFiles = async (project)=>{
        const api_url = process.env.REACT_APP_API_BASE + `folders/${project.projectName.split(" ").join("")}/folderContent`;
        const payload = {
            folderPath:`${project.projectName.split(" ").join("")}`
        }
        const response = await axios.post(api_url,payload, config)
        if (response.status===200){
            setFiles(response.data.data.files)
        }
    }
    const handleOpenFolder=(path)=>{
       window.location.href = `/view-files/${id}/${path}#9`
    }
    const onCloseClick = () =>{
        setFolderPath('')
        setShowCreateFolder(false)
    }
    const handleFolderNameChange = (event) => {
        setFolderPath(event.target.value);
    };
    useEffect(() => {
        getProjectDetails()

    }, []);

    const downloadFiles = async (url) =>{
        const api_url = process.env.REACT_APP_API_BASE + `files/download`
        const payload = {
            filePath:url
        }
        const response = await axios.post(api_url, payload, config)
        if (response.status===200){
            window.open(response.data.data.downloadUrl, '_blank')
        }

    }
    return (
        <>
            <ToastContainer autoClose={3000} />
            <div className="page-content">
                <DashboardNavigation projectTitle={"Documents"} />
                <Modal
                    isOpen={showCreateFolder}
                    toggle={onCloseClick}
                >
                        <form
                            className="w-100 needs-validation"
                            noValidate
                            onSubmit={handleCreateFolder}
                        >
                            <div className="d-flex flex-column w-100 p-4">
                                <h3>New Folder</h3>
                            </div>
                           <div className={"container mb-3 px-4 create-modal"}>
                               <div className={"mb-3"}>
                                   <input required={true} type={"text"} className={"form-control"} placeholder={"Folder name"} onChange={handleFolderNameChange}/>
                               </div>
                               <div className={"container-fluid d-flex justify-content-end"}>
                                   <h6 onClick={onCloseClick}>Cancel</h6>
                                   <h6 onClick={handleCreateFolder}>Create</h6>
                               </div>

                           </div>
                        </form>
                </Modal>
                <div className={"container-fluid px-3"}>
                    <Row>
                        <Col sm="12">
                            <Row>
                                <Col xl={4} md={4} xs={12}>
                                    <h4 className="card-title">Documents</h4>
                                    <p className="card-title-desc mb-3">
                                        Track documents for this project
                                    </p>
                                </Col>
                                <Col xl={8} md={8} xs={12}>
                                   <div className={"container-fluid d-flex justify-content-end"}>
                                       <div className={"md-3 me-5"}>
                                           <input type={"text"} onChange={handleSearchChange} className={"form-control search-field"} placeholder={"Search"}/>
                                       </div>
                                       <button
                                           className="btn btn-primary me-3"
                                           style={{ background: '#194973' }}
                                           onClick={() => setShowCreateFolder(true)}
                                       >
                                           Create folder
                                       </button>
                                       <button
                                           className="btn btn-primary me-3"
                                           style={{ background: '#194973' }}
                                           onClick={() => folderInputRef.current.click()}
                                       >
                                           <img src="/Folder - Upload.svg" alt="Upload Folder" /> Upload folder
                                       </button>
                                       <input
                                           type="file"
                                           webkitdirectory="true"
                                           style={{ display: 'none' }}
                                           ref={folderInputRef}
                                           onChange={handleFolderUpload}
                                       />

                                       <button
                                           className="btn btn-primary me-3"
                                           style={{ background: '#194973' }}
                                           onClick={() => fileInputRef.current.click()}
                                       >
                                           <img src="/File - Check.svg" alt="Upload File" /> Upload file
                                       </button>
                                       <input
                                           type="file"
                                           style={{ display: 'none' }}
                                           ref={fileInputRef}
                                           onChange={handleFileChange}
                                           multiple={true}
                                       />
                                   </div>
                                </Col>
                            </Row>
                            <Card>
                                {
                                    uploadingStatus && (
                                        <>
                                            Uploading
                                            <Progress
                                                value={progress}
                                                color="primary"
                                                className=""
                                            >
                                                <span>{progress}%</span>
                                            </Progress>
                                        </>
                                    )
                                }

                                <CardBody className={"p-0"}>
                                    <div className="table-responsive">
                                        <Table className="table mb-0">
                                            <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Path</th>
                                                <th>Added by</th>
                                                <th>Last update</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                searchQuery.length > 0 ? <>
                                                    {
                                                        filteredFolders.length > 0 && (
                                                            <>
                                                                {
                                                                    filteredFolders.map((folder, index)=>(
                                                                        <tr onClick={() => handleOpenFolder(folder.name)} key={index}>
                                                                            <td><img src="/Folder.png" alt="Folder" /> {folder.name}</td>
                                                                            <td>{folder.path}</td>
                                                                            <td>{folder.createdBy ? folder.createdBy.email : ''}</td>
                                                                            <td></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </>
                                                        )

                                                    }
                                                    {
                                                        filteredFiles.length > 0 && (
                                                            <>
                                                                {
                                                                    filteredFiles.map((file, index)=>(
                                                                        <tr onClick={() => downloadFiles(file.public_id)} key={index}>
                                                                            <td><img src="/File.png" alt="File" /> {file.context.custom.originalFilename}</td>
                                                                            <td>{file.path}</td>
                                                                            <td></td>
                                                                            <td></td>
                                                                        </tr>
                                                                    ))
                                                                }
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        filteredFiles.length===0 && filteredFolders.length===0 && (
                                                            <p>No results found</p>
                                                        )
                                                    }
                                                </> : <>
                                                    {
                                                        folders.map((folder, index)=>(
                                                            <tr onClick={() => handleOpenFolder(folder.name)} key={index}>
                                                                <td><img src="/Folder.png" alt="Folder" /> {folder.name}</td>
                                                                <td>{folder.path}</td>
                                                                <td>{folder.createdBy ? folder.createdBy.email : ''}</td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }
                                                    {
                                                        files.map((file, index)=>(
                                                            <tr onClick={() => downloadFiles(file.public_id)} key={index}>
                                                                <td><img src="/File.png" alt="File" /> {file.context.custom.originalFilename}</td>
                                                                <td>{file.path}</td>
                                                                <td></td>
                                                                <td></td>
                                                            </tr>
                                                        ))
                                                    }
                                                </>
                                            }


                                            </tbody>


                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </>

    );
}

export default Documents
