import React, {useEffect, useState} from "react"
import FeatherIcon from "feather-icons-react";
import AddUserRoles from "./AddUserRoles";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Col,
    Row,
    Table
} from "reactstrap";
import axios from "axios";
import {useParams} from "react-router-dom";
import EditUserRoles from "./EditUserRoles";

export default function UserRoles(){
    const {id} = useParams()
    const[loading, setLoading]=useState(true)
    const [addRoles, setAddRoles]=useState(false)
    const[roles, setRoles]=useState({})
    const [roleDetails, setRoleDetails] = useState([])
    const api_url = process.env.REACT_APP_API_BASE + `roles?projectId=${id}`;
    const[editRole, setEditRole]=useState(false)
    const[selectedColumn, setSelectedColumn]=useState(false)
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('authUser')).token
        }
    };
    const getRoles = async ()=>{
        try {
            setLoading(false)
            const response = await axios.get(api_url, config)
            setRoles(response.data.data)
        } catch (e) {
            setLoading(false)
        } finally {
            setLoading(false)
        }

    }
    useEffect(()=>{
            getRoles()
    }, [])
    const handleClose = () => {
        setAddRoles(false)
        setEditRole(false)
        setSelectedColumn(false)
        setRoleDetails({})
    }
    const handleViewRole = async (id)=>{
        const api_url = process.env.REACT_APP_API_BASE + `roles/${id}`;
        const response = await axios.get(api_url, config)
        setSelectedColumn(true)
        setRoleDetails(response.data.data)
        setEditRole(true)
        console.log(response.data.data)
    }
    return (
        <>
            <AddUserRoles addRole={addRoles} onClose={handleClose} getRoles={getRoles} />
            {
                selectedColumn && (
                    <EditUserRoles editRole={editRole} onClose={handleClose} getRoles={getRoles} roleDetails={roleDetails} />
                )
            }

            <Row className={"d-flex align-items-center"}>
                <Col xl={6} md={6} xs={12}>
                    <h4 className="card-title fw-bold">User Roles</h4>
                    <p className={"card-title-desc"}>
                        View and Edit Roles for your Workspace
                    </p>
                </Col>
                <Col xl={6} md={6} xs={12} className={"d-flex justify-content-end"}>
                    <Button
                        type="button"
                        color="primary"
                        style={{ marginRight: '1rem' }}
                        className={"d-flex align-items-center"}
                        onClick={()=>setAddRoles(!addRoles)}
                    >
                        <FeatherIcon
                            icon="plus"
                            style={{height:'15px'}}
                        />
                        Add Role

                    </Button>
                </Col>
            </Row>
            <table className={"table task-progress mt-3 border"}>
                <thead>
                <tr>
                    <th>Role</th>
                    <th>Role Description</th>
                    <th>Created By</th>
                    <th>Date Created</th>
                    <th>Members</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {
                    loading ? <>
                    <p>Loading...</p>
                    </> : <>
                        {
                            roles.length > 0 ? <>
                                {
                                    roles.map((role, index)=>(
                                        <tr key={index} onClick={()=>handleViewRole(role._id)}>
                                            <td>{role.name}</td>
                                            <td>{role.description}</td>
                                            <td></td>
                                            <td>{new Date(role.createdAt).toDateString()}</td>
                                            <td></td>
                                        </tr>
                                    ))
                                }
                            </> : <>
                            <tr>
                                <td colSpan={5}><p className={"text-center"}>No Roles Added</p></td>
                            </tr>
                            </>
                        }
                    </>
                }

                </tbody>
            </table>
        </>
    )
}